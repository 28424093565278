import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/src/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/add-slim.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/amazon-music.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/apple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/arrow-triangle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/arrowDown.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/author-placeholder.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/back.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/chevron.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/clock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/close-cross.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/companySize.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/cross-product.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/document-spanner.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/document.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/dropdown.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/duration.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/feature.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/funnel.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/g2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/glassdoorSquareColored.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/globe.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/google-podcast.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/hamburgerMenu.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/home.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/idea-gear-bulb.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/industry.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/insights.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/instagramSquareColored.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/link.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/linkedInSquareColored.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/linkedinV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/location.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/magic.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/mailEnvelope.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/marketing.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/minus-slim.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/minus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/organization.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/placeholder.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/play-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/play.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/playRounded.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/playV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/plus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/plusRounded.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/profile.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/quote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/refresh.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/resultsNotFound.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/reviewQuote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/rounded-checkbox.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/search.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/searchV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/service.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/share.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/social.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/spotify.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/sprinklr-burst-monotone.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/sprinklrBurst.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/sprinklrFullLogoDark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/stats.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/tick-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/video-player.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/yinyang-desktop-lg.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/yinyang-desktop-sm.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/yinyang-tablet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/youtube-music.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/youtube.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/breadcrumb/BreadCrumbItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CallToAction/behaviour/ClickAction.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CallToAction/behaviour/LinkAction.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/curvedTopBackground/CurvedTopBackground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/form/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/InfiniteScroller.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Link/ExternalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/renderBuilderContent/index.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/resourceGrid/components/ResourceGridItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LegacyAccordion"] */ "/vercel/path0/src/components/richText/components/LegacyAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ScrollListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShareLink"] */ "/vercel/path0/src/components/shareLinks/ShareLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/tabs/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/src/components/ui/accordionFAQ.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogContent","DialogClose"] */ "/vercel/path0/src/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useToast"] */ "/vercel/path0/src/components/ui/use-toast.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgetErrorBoundary/WidgetErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/accordionDeluxe/AccordionDeluxe.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/animatedQuoteDisplayCarousel/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/animatedStatsGrid/components/StatCard.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/animatedTimeline/components/Journey.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogHomepage/components/blogCategoriesListingSection/components/CategoryFilterSection/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogHomepage/components/blogCategoriesListingSection/components/CategorySectionContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogHomepage/components/blogCategoriesListingSection/context/BlogListingContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogPostContent/components/ShareLink.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogPostContent/components/SideBar/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/blogPostContent/components/Subnav.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BodyDimensionsContextProvider"] */ "/vercel/path0/src/components/widgets/blogPostContent/context/BodyDimensionsContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/blogPostContent/context/SubNavHeightContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/Carousel/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/carouselWidget/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/componentProvider/ComponentProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/contentCarousel/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/contentMediaGrid/components/SubNav.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/customerStories/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/vercel/path0/src/components/widgets/customerStoryHero/components/BreadCrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/customerStoryHero/components/CustomerStoryHeroCarousel.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/customTOC/ListItem.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/customUnorderedList/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/cxWiseLandingHero/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/cxWiseResourceList/components/ResourceList.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/featureComparisonTable/components/CollapsibleContainer.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/floatingNavbar/FloatingNavbarClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/formWidget/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/glossaryDetailBody/components/SubNavBar.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/glossaryLandingWidget/components/GlossaryListingSection/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/glossaryLandingWidget/components/GlossarySubNav/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/glossaryLandingWidget/components/SearchboxWrapper/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/glossaryLandingWidget/context/GlossaryContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/heroBackgroundImage/components/CurvedImageContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/HomeHero/components/HeroContainer.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/assets/digital-twin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/assets/figure-desktop-lg.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/assets/figure-desktop-sm.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/assets/figure-mobile.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/assets/figure-tablet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/widgets/marketecture/components/Hoverable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/mathematicalFormulas/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/OfferingCard/components/CardPillGrid.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/pricingCardTemplate/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/productCustomerStories/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/vercel/path0/src/components/widgets/productHero/components/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/productSectionTab/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/relatedArticles/components/MobileVersion.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/searchableGrid/SearchableGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/snapCarouselWidget/components/SnapCarousel.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/solutionTabSection/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/stickySubMenu/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/stickySubNavWrapper/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/storiesGrid/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/subMenuBar/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/widgets/tableOfContents/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderHeightListener"] */ "/vercel/path0/src/contexts/HeaderHeightContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageContextProvider"] */ "/vercel/path0/src/contexts/PageContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/src/external/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/externalComponent/ExternalComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/src/external/footer/components/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FirstRowWrapper"] */ "/vercel/path0/src/external/header/components/FirstRowWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderIconConditionalWrapper"] */ "/vercel/path0/src/external/header/components/HeaderIconConditionalWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/external/header/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/external/header/components/SecondRowElementsWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeightObserver"] */ "/vercel/path0/src/external/header/HeightObserver.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/external/header/layouts/Mobile.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/add-slim.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/amazon-music.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/apple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/arrow-triangle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/arrowDown.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/author-placeholder.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/back.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/chevron.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/clock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/close-cross.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/Close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/companySize.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/cross-product.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/document-spanner.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/document.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/dropdown.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/duration.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/feature.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/funnel.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/g2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/globe.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/google-podcast.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/hamburgerMenu.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/home.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/idea-gear-bulb.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/industry.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/insights.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/link.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/linkedinV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/location.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/magic.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/mailEnvelope.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/marketing.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/minus-slim.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/minus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/organization.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/play-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/play.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/playRounded.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/playV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/plus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/plusRounded.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/profile.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/quote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/refresh.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/resultsNotFound.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/reviewQuote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/rounded-checkbox.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/search.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/searchV2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/service.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/share.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/social.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/spotify.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/sprinklr-burst-monotone.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/sprinklrBurst.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/sprinklrFullLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/sprinklrFullLogoDark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/sprinklrName.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/stats.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/tick-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/video-player.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/youtube-music.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/external/icon/assets/youtube.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/external/languageSelector/variants/Desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/external/languageSelector/variants/Mobile.tsx");
